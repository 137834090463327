import { Box, Dialog, IconButton, ThemeProvider, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Close } from '@portals/icons';
import { PersonMale } from '@portals/icons/src/PersonMale/PersonMale';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { loginConfig, LoginContext } from '../../contexts/LoginContext';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn/useIsLoggedIn';
import { extractCode, getLoginIframeUrl, loginToUserApi, saveAfterRegistrationAction } from '../../utils/userApi';
import { afterRegistrationAction, UserContext } from '../LoginHandler/LoginHandler';

const desktopButtonStyle = {
  boxShadow: '0px 0px 0px 1px white',
  display: { xs: 'none', lg: 'inline-flex' },
};

const marginIframe = 20;
export const LoginButton = (): React.ReactElement => {
  const { t } = useTranslation();
  const [iframeHeight, setIframeHeight] = useState(360);
  const {
    showIframe,
    setIframeVisibility,
    onLogin,
    checkForLoginStateUpdates,
    afterRegisterParams,
    resetAfterRegistrationState,
    isRequestingLoginState,
  } = useContext(UserContext);
  const loginContext = useContext(LoginContext);
  const [loginState] = useIsLoggedIn();
  const router = useRouter();
  const theme = useTheme();

  const handleLoginMessage = async (code: string, redirectURI: string) => {
    if (await loginToUserApi(loginContext.userApiUrl, code, redirectURI)) {
      setIframeVisibility(false);
      onLogin();
      await checkForLoginStateUpdates();
    }
  };

  const messageHandler = async (msg: MessageEvent) => {
    const code = extractCode(msg.data);
    if (code) {
      await handleLoginMessage(code, loginContext.redirectUri);
    }
    if (msg.data.height) {
      setIframeHeight(msg.data.height);
    }

    if (msg.data?.page === 'login-verify-email') {
      if (afterRegistrationAction) {
        await saveAfterRegistrationAction(loginConfig.userApiUrl, afterRegistrationAction);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
      closeDialog();
    };
  }, []);

  const url = getLoginIframeUrl(loginContext, afterRegisterParams);
  const closeDialog = useCallback(async () => {
    setIframeVisibility(false);
    resetAfterRegistrationState();
    if (window?.location?.pathname?.startsWith('/mein-bereich')) {
      await router.push('/');
    }
  }, []);

  const showDialog = useCallback(() => {
    setIframeVisibility(true);
  }, []);

  const loggedInButton = (
    <>
      <Button sx={desktopButtonStyle} href="/mein-bereich.html">
        {t('userDashboard.headerButton')}
      </Button>
      <IconButton href="/mein-bereich.html" sx={{ display: { lg: 'none' } }}>
        <PersonMale />
      </IconButton>
    </>
  );

  const notLoggedInButton = (
    <>
      <Button sx={desktopButtonStyle} onClick={showDialog}>
        {t('userDashboard.headerButton')}
      </Button>
      <IconButton onClick={showDialog} sx={{ display: { lg: 'none' } }}>
        <PersonMale />
      </IconButton>
    </>
  );

  const closeButton = (
    <Box textAlign="right">
      <IconButton onClick={closeDialog} sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>
        <Close color="secondary" />
      </IconButton>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      {loginState && loggedInButton}
      {!loginState && notLoggedInButton}
      <Dialog
        open={showIframe && !isRequestingLoginState}
        PaperProps={{
          sx: { borderRadius: { xs: 0, lg: '12px' } },
        }}
      >
        {closeButton}
        <iframe
          src={url}
          style={{ width: '600px', maxWidth: '100%', height: iframeHeight + marginIframe, border: '0' }}
          title="Keylcloaklogin"
        ></iframe>
      </Dialog>
    </ThemeProvider>
  );
};

LoginButton.displayName = 'LoginButton';
